import { useNavigate } from "react-router-dom";

const TermsOfUse = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
      >
        ← Back
      </button>

      <div className="ml-10 mobile:ml-4">
        <h1 className="text-2xl font-bold">
          Ethiomaps Terms of Use and Privacy Policy
        </h1>
        <hr className="my-4 border-gray-300" />

        {/* Terms of Use Section */}
        <h2 className="text-xl font-semibold">Terms of Use</h2>
        <p className="text-gray-600">Effective Date: Feb 28, 2025</p>
        <p>
          Welcome to <strong>Ethiomaps</strong> ("the App"), a navigation and
          mapping service that provides location search, routing, and navigation
          assistance. By using our services, you agree to these Terms of Use. If
          you do not agree, please do not use the App.
        </p>
        <hr className="my-4 border-gray-300" />

        {/* Terms of Use Sections */}
        {[
          {
            title: "1. Acceptance of Terms",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  By accessing or using the App, you agree to be bound by these
                  Terms of Use and any applicable laws and regulations.
                </li>
              </ul>
            ),
          },

          {
            title: "2. Use of Services",
            content: (
              <>
                <p>
                  The App provides location-based services, including search,
                  routing, and navigation.
                </p>
                <ul className="list-disc pl-6 mt-2">
                  <li>
                    You must not use the App for illegal activities, including
                    but not limited to misleading navigation, stalking, or
                    violating privacy laws.
                  </li>
                  <li>
                    You are responsible for ensuring that your use of the App
                    does not violate any applicable traffic laws.
                  </li>
                </ul>
              </>
            ),
          },

          {
            title: "3. User Responsibilities",
            content: (
              <ul className="list-disc pl-6 mt-2">
                <li>
                  You must not interfere with or disrupt the App’s functionality
                  or security.
                </li>
                <li>
                  You are responsible for maintaining the confidentiality of
                  your account and any actions taken under your account.
                </li>
                <li>
                  You acknowledge that navigation recommendations are based on
                  available data and should be used with personal judgment.
                </li>
              </ul>
            ),
          },

          {
            title: "4. OpenStreetMap (OSM) Data Usage",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  Ethiomaps uses map data from OpenStreetMap (OSM), which is
                  made available under the Open Database License (ODbL). More
                  information about the license can be found at{" "}
                  <a
                    href="https://www.openstreetmap.org/copyright"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    OpenStreetMap Copyright
                  </a>
                  .
                </li>
                <li>
                  Since OSM is a community-driven platform, we do not guarantee
                  the accuracy, completeness, or timeliness of the map data
                  provided.
                </li>
              </ul>
            ),
          },

          {
            title: "5. Data Accuracy and Availability",
            content: (
              <ul className="list-disc pl-6 mt-2">
                <li>
                  While we strive to provide accurate information, we do not
                  guarantee the correctness of map data, routes, or locations.
                </li>
                <li>
                  The App may experience interruptions due to maintenance,
                  updates, or external factors beyond our control.
                </li>
              </ul>
            ),
          },

          {
            title: "6. Intellectual Property",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  You may not modify, reproduce, or distribute the App without
                  permission.
                </li>
              </ul>
            ),
          },

          {
            title: "7. Limitation of Liability",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  We are not responsible for any damages, direct or indirect,
                  resulting from your use of the App.
                </li>
                <li>
                  The App is provided "as is" without warranties of any kind.
                </li>
              </ul>
            ),
          },

          {
            title: "8. Changes to Terms",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  We reserve the right to update these Terms of Use at any time.
                  Continued use of the App after changes constitutes acceptance
                  of the new terms.
                </li>
              </ul>
            ),
          },

          {
            title: "9. Contact Information",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  If you have any questions, please contact us at{" "}
                  <a
                    href="mailto:admin@yehatech.com"
                    className="text-blue-500 hover:underline"
                  >
                    admin@yehatech.com
                  </a>
                  .
                </li>
              </ul>
            ),
          },
        ].map((section, index) => (
          <div key={index} className="mt-4">
            <h3 className="font-semibold">{section.title}</h3>
            <p className="whitespace-pre-line">{section.content}</p>
            <hr className="my-4 border-gray-300" />
          </div>
        ))}

        {/* Privacy Policy Section */}
        <h2 className="text-xl font-semibold mt-6">Privacy Policy</h2>
        <p className="text-gray-600">Effective Date: Feb 28, 2025</p>
        <p>
          At <strong>Ethiomaps</strong>, we take your privacy seriously. This
          Privacy Policy explains how we collect, use, and protect your personal
          data.
        </p>
        <hr className="my-4 border-gray-300" />

        {/* Privacy Policy Sections */}
        {[
          {
            title: "1. Information We Collect",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  <strong>Location Data:</strong> The App collects your GPS
                  location to provide navigation and routing services.
                </li>
                <li>
                  <strong>Usage Data:</strong> We collect data about your
                  interactions with the App, such as search queries, routes
                  taken, and app performance.
                </li>
                <li>
                  <strong>Device Information:</strong> We may collect device
                  details such as model, operating system, and IP address.
                </li>
              </ul>
            ),
          },

          {
            title: "2. How We Use Your Information",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>To provide navigation, search, and routing services.</li>
                <li>To improve App functionality and user experience.</li>
                <li>To analyze usage trends and enhance security.</li>
                <li>To comply with legal obligations.</li>
              </ul>
            ),
          },

          {
            title: "3. Data Sharing",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>We do not sell your personal data.</li>
                <li>
                  We may share data with service providers for analytics, cloud
                  storage, and functionality improvements.
                </li>
                <li>
                  We may disclose data if required by law or for security
                  purposes.
                </li>
              </ul>
            ),
          },

          {
            title: "4. Data Security",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  We implement security measures to protect your information
                  from unauthorized access.
                </li>
                <li>
                  However, no system is completely secure, and we cannot
                  guarantee absolute security.
                </li>
              </ul>
            ),
          },

          {
            title: "5. Your Choices and Rights",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  You can disable location services through your device
                  settings, but this may limit App functionality.
                </li>
                <li>
                  You can request data deletion by contacting us at{" "}
                  <a
                    href="mailto:admin@yehatech.com"
                    className="text-blue-500 hover:underline"
                  >
                    admin@yehatech.com
                  </a>
                  .
                </li>
              </ul>
            ),
          },

          {
            title: "6. Changes to Privacy Policy",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  We may update this Privacy Policy from time to time. Continued
                  use of the App after changes means you accept the updated
                  policy.
                </li>
              </ul>
            ),
          },

          {
            title: "7. Contact Information",
            content: (
              <ul className="list-disc pl-5 mt-2">
                <li>
                  For any privacy-related questions, contact us at{" "}
                  <a
                    href="mailto:admin@yehatech.com"
                    className="text-blue-500 hover:underline"
                  >
                    admin@yehatech.com
                  </a>
                  .
                </li>
              </ul>
            ),
          },
        ].map((section, index) => (
          <div key={index} className="mt-4">
            <h3 className="font-semibold">{section.title}</h3>
            <p className="whitespace-pre-line">{section.content}</p>
            <hr className="my-4 border-gray-300" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfUse;
