import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./containers/App/App";
import Signin from "./containers/Pages/Signin/signin";
import Signup from "./containers/Pages/Signup/Signup";
import AddPlaceForm from "./containers/Places/form";
import Terms from "./containers/Terms";
import { connect } from "react-redux";

// const RestrictedRoute = ({ element: Component, isAuthenticated, ...rest }) => {
//   return isAuthenticated ? (<Outlet />) : (<Navigate to="/signin" replace state={{ from: rest.location }} /> );
// };

const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router basename="/map">
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/form" element={<AddPlaceForm />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
  };
}

export default connect(mapStateToProps)(PublicRoutes);
